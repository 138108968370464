import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';

@Injectable({
  providedIn: 'root'
})
export class SeverityViewService extends AbstractRestService {
  postInit(): void {}

  getDetailData(CurrentShift: string = "True", 
               StartDate: string = "", 
               EndDate: string = "", 
               plant: string = "", 
               line?: string, 
               severity?: string) :Observable<GenericLambdaReturn<SeverityType[]>> {
    let params:any={
      "CurrentShift": CurrentShift,
      "StartDate": StartDate,
      "EndDate": EndDate,
      "Site": plant
    };

    line ? params.Line = line:null;
    severity ? params.Severity = severity:null;

    return this.get({
      ApiResource: '/details',
      data: params
    });
  }

  getpic(ResultId: string, field: string, site: string):Observable<Blob> {
    let params: any = {};

    params.ResultID = ResultId;
    params.Field = field;
    params.Site =  site;
  
    console.log(' Inside service severity ')
    return this.getBinary({
      ApiResource: "/picture",
      data: params
    });
  }
  getDetailConfig(plant: string) :Observable<GenericLambdaReturn<DetailConfigType[]>> {
    let params:any={
      "Site": plant
    };

    return this.get({
      ApiResource: '/detailconfig',
      data: params
    });
  }

  updateAcknowledgementDetails(Site:string, ResultID:string, Comment:string ,Userid:string , Acknowledgeresultdid:string) :Observable<GenericLambdaReturn<any>>
  {
    let params: any = { };

    params.site = Site
    params.resultID = ResultID
    params.comment = Comment
    params.userid = Userid
    params.update = (Acknowledgeresultdid != null) ? 1 : 0;

    console.log(params);
    console.log(Acknowledgeresultdid);


    return this.post({
      ApiResource: "/acknowledge",
      data: {data: params},
      repeatCall: false
    })

  }

}

export type SeverityType = {
  ShiftDate: string;
  Shift: string;
  site: string;
  Line: string;
  ResultDateTime: string;
  Model: string;
  Serial: string;
  ResultId : string;
  AreaDescription: string;
  ComponentDescription: string;
  DefectDescription: string;
  Severity: string;
  Note: string;
  OperatorName: string;
  NewDetails: NewDetailType[];
  AcknowledgeDetails : AcknowledgeDetailType [];
  Acknowledgeresultid : string;
}

export type NewDetailType = {
  VariableData1: string; 
  VariableData2: string;
  CustomData1: string;
  CustomData2: string;
  CustomData3: string;
  CustomData4: string;
  CustomData5: string;
  CustomData6: string;
  CustomData7: string;
  CustomData8: string;
  CustomData9: string;
  CustomData10: string;
}

export type AcknowledgeDetailType = {
  Comment :string;
  InsertDate :string;
  UpdateDate :string;
  UserID :string;
}
export type DetailConfigType = {
  Id: number;
  DataField: string;
  Description: string;
}
 